import { VStack, Divider, Text, HStack } from "@chakra-ui/react";

import {
  BasePrice,
  CashPrice,
  DistanceAdjustment,
  Multiplier,
  NetPrice,
  NetTotal,
  StorageFees,
} from "components/PricingStats/PricingStats";
import { DelayedContractCurrentPricingResponse } from "api";
import WithSpinner from "components/WithSpinner";
import { useCustomValidation } from "forms/delayed-pricing/hooks/useCustomValidation";
import useCropYearDetail from "hooks/crop-year/useCropYearDetails";
import useGetDelayedPricingCalculatedContract from "hooks/pricing-option/useGetDelayedPricingCalculatedContract";
import useSelectedCropYearId from "hooks/useSelectedCropYearId";
import useSelectedFarmId from "hooks/useSelectedFarmId";
import { ISODateWithoutTime } from "utils/dateUtils";

const DelayedPricingStats = ({
  currentDelayedPricingData,
  tonsToSell,
  dateOfContract,
  cashPrice,
  isManuallyCreated = false,
}: {
  currentDelayedPricingData: DelayedContractCurrentPricingResponse | undefined;
  tonsToSell: number;
  dateOfContract?: Date | null;
  cashPrice?: number;
  isManuallyCreated: boolean;
}) => {
  const [selectedCropYearId] = useSelectedCropYearId();
  const [selectedFarmId] = useSelectedFarmId();
  const { data: cropYearDetailData } = useCropYearDetail(selectedCropYearId);
  const {
    data: delayedPricingCalculatedData,
    isLoading: delayedPricingCalculatedDataIsLoading,
  } = useGetDelayedPricingCalculatedContract({
    cropYearId: selectedCropYearId,
    farmId: selectedFarmId,
    futuresPrice: Number(currentDelayedPricingData?.futuresPrice ?? 0),
    basis: currentDelayedPricingData?.basis ?? 0,
    tons: Number(tonsToSell ?? 0),
    dateOfContract: dateOfContract
      ? ISODateWithoutTime(dateOfContract)
      : undefined,
    cashPrice,
    isManuallyCreated,
  });
  useCustomValidation(delayedPricingCalculatedData);

  return (
    <>
      <VStack align="left" spacing={4}>
        {isManuallyCreated ? null : (
          <CashPrice
            cashPrice={currentDelayedPricingData?.cashPrice}
            futuresFriendlySymbol={
              currentDelayedPricingData?.futuresFriendlySymbol
            }
            futuresPrice={currentDelayedPricingData?.futuresPrice}
            basis={currentDelayedPricingData?.basis}
          />
        )}
        <Multiplier multiplier={delayedPricingCalculatedData?.multiplier} />
        <BasePrice basePrice={delayedPricingCalculatedData?.basePrice} />
      </VStack>
      <Divider color="grayFactor.2" opacity={1} borderWidth="1px" />
      <VStack align="left" spacing={4}>
        <DistanceAdjustment
          distanceAdjustment={
            delayedPricingCalculatedData?.distanceAdjustmentPerTon
          }
        />
        {cropYearDetailData?.storageFeeRate !== 0 && (
          <StorageFees
            storageFees={delayedPricingCalculatedData?.storageFeeAdjustment}
            storageFeeRate={cropYearDetailData?.storageFeeRate}
            storageFeeStartDate={cropYearDetailData?.storageFeeStartDate}
          />
        )}
        <NetPrice netPrice={delayedPricingCalculatedData?.netPrice} />
      </VStack>
      <Divider color="grayFactor.2" opacity={1} borderWidth="1px" />

      <HStack justify="space-between">
        <Text fontWeight="bold" color="charcoal">
          Net Total
        </Text>
        <WithSpinner
          isLoading={!!delayedPricingCalculatedDataIsLoading}
          spinnerProps={{ size: "sm" }}
          centerProps={{ minH: 12 }}
        >
          <NetTotal netTotal={delayedPricingCalculatedData?.netTotal} />
        </WithSpinner>
      </HStack>
    </>
  );
};

export default DelayedPricingStats;
