import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateCropYearRequest } from "api";
import { useCropYearService } from "contexts/ServiceProvider";
import CropYearService from "services/CropYearService";
import { MutationHook, MutationHookResult } from "../hookTypeHelpers";
import { GET_CROP_YEAR_DETAIL_QUERY_KEY } from "./useCropYearDetails";
import { GET_CROP_YEARS_QUERY_KEY } from "hooks/crop-year/useCropYears";
import { GET_FARM_CROP_YEAR_SUMMARY_QUERY_KEY } from "./useFarmCropYearSummary";
import { GET_CROP_YEAR_SUMMARY_QUERY_KEY } from "./useCropYearSummary";

export const CREATE_CROP_YEAR_QUERY_KEY = "create-crop-year";

type CreateCropYearMutationResult = MutationHookResult<
  Awaited<ReturnType<CropYearService["createCropYear"]>>,
  CreateCropYearRequest
>;

const useCreateCropYearMutation = (): CreateCropYearMutationResult => {
  const cropYearService = useCropYearService();

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [CREATE_CROP_YEAR_QUERY_KEY],
    mutationFn: (cropYearInfo) => cropYearService.createCropYear(cropYearInfo),
    onSuccess: () =>
      Promise.all([
        queryClient.refetchQueries({
          queryKey: [GET_CROP_YEAR_DETAIL_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_CROP_YEARS_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_FARM_CROP_YEAR_SUMMARY_QUERY_KEY],
        }),
        queryClient.refetchQueries({
          queryKey: [GET_CROP_YEAR_SUMMARY_QUERY_KEY],
        }),
      ]),
  });
};

const useCreateCropYear = (): MutationHook<
  CreateCropYearMutationResult,
  "onSubmit"
> => {
  const {
    mutate: onSubmit,
    mutateAsync: onSubmitAsync,
    ...rest
  } = useCreateCropYearMutation();

  return { onSubmit, onSubmitAsync, ...rest };
};

export default useCreateCropYear;
